import { useRouter } from "next/router";
import "../styles/globals.css";
import { useEffect } from "react";
import * as gtag from "utils/gtag";
import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { useLinguiInit } from "utils/i18n";


function MyApp({ Component, pageProps }: any) {
  const router = useRouter();
  useLinguiInit(pageProps?.translation);
  useEffect(() => {
    window.history.scrollRestoration = 'manual';
    const handleRouteChange = (url: URL) => {
      gtag.pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <I18nProvider i18n={i18n}>
      <Component {...pageProps} />
    </I18nProvider>
  );
}

export default MyApp;
